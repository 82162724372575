import { Component, OnInit, ViewChild } from '@angular/core';
import { Project } from '../../model/project';
import { ProjectService } from '../../services/project.service';
import { first } from 'rxjs/operators';
import { pop } from 'src/app/animation';
@Component({
  selector: 'app-travaux',
  templateUrl: './travaux.component.html',
  styleUrls: ['./travaux.component.scss'],
  animations : [
    pop
  ]
})
export class TravauxComponent implements OnInit {
  Projects : Project[];
  isClickedArray : Boolean[] = [];
  show = false;
  isSelectNum = 0;
  nowProjectIndex = 0;
  constructor(private ProductService : ProjectService) { }
  ngOnInit() {
    window.scrollTo(0, 0);
    this.ProductService.getProjects().pipe(first()).subscribe(Projects => {
      if(!Projects){
        console.log("no project");
        return;
      }else{
        this.Projects = Projects;
        for (var i=0; i< this.Projects.length; i++){
          this.isClickedArray.push(false);
        }
      }
    })
  }

  get stateName() {
    return this.isClickedArray[this.nowProjectIndex] ? 'show' : 'hide'
  }

  SetnowProjectIndex(input_: number){
    this.nowProjectIndex = input_;
    //console.log(this.nowProjectIndex);
  }

  onSelect(index : number): void {
    this.show = !this.show;
    this.isClickedArray[index] = !this.isClickedArray[index];
    //console.log();
    console.log(this.isClickedArray[index]);
    this.isSelectNum = index;
  }

}
