import { Component, ViewEncapsulation, HostListener } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation : ViewEncapsulation.None

})
export class AppComponent {
  title = 'midw';
  date = new Date();
  thisyear = this.date.getFullYear();
}
