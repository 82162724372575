import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TravauxComponent } from './components/travaux/travaux.component';
import { ProfileComponent } from './components/profile/profile.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { environment } from '../environments/environment';
import { Observable } from 'rxjs'
import { HttpClientModule} from '@angular/common/http';
import { AngularEditorModule} from '@kolkov/angular-editor';
import { ProjectService } from './services/project.service';
import { MakepostComponent } from './components/makepost/makepost.component';
import { PostsComponent } from './components/posts/posts.component';
import { NewpostComponent } from './components/newpost/newpost.component';


@NgModule({
  declarations: [
    AppComponent,
    TravauxComponent,
    ProfileComponent,
    MakepostComponent,
    PostsComponent,
    NewpostComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgbModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    HttpClientModule,
    AngularEditorModule
  ],
  providers: [ProjectService],
  bootstrap: [AppComponent]
})
export class AppModule { }
