import { trigger, state, transition, style, animate } from '@angular/animations';
const animateIn = '0.15s ease-in';
const animateOut = '0.25s ease-out';

export let pop = trigger('popOverState', [
  state('show', style({
    opacity: 1,
  })),
  state('hide', style({
    opacity: 0,
  })),
  transition('show => hide', animate('350ms ease-out')),
  transition('hide => show', animate('750ms ease-in'))
])
