import { Routes, RouterModule } from '@angular/router';
import { TravauxComponent } from './components/travaux/travaux.component';
import { ProfileComponent } from './components/profile/profile.component';
import { MakepostComponent } from './components/makepost/makepost.component';
import { PostsComponent } from './components/posts/posts.component';
import { NewpostComponent } from './components/newpost/newpost.component';

const routes: Routes = [
  {path :'',redirectTo:"/main", pathMatch:"full"},
  {path : 'main', component: TravauxComponent},
  {path : 'profile', component : ProfileComponent},
  {path : 'makepost', component : MakepostComponent},
  {path : "post" , component :PostsComponent },
  {path : "newpost", component : NewpostComponent }
];


export const AppRoutingModule = RouterModule.forRoot(routes, {useHash : true});
