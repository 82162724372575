import { Component, OnInit } from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { AuthService } from "../../services/auth.service";
import { Project } from '../../model/project';
import { ProjectService } from '../../services/project.service';
import { first } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-makepost',
  templateUrl: './makepost.component.html',
  styleUrls: ['./makepost.component.scss']
})


export class MakepostComponent implements OnInit {
  form: FormGroup;
  htmlContent = '';
  htmlContent1 = '';
  Projects : Project[];

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    minHeight: '5rem',
    maxHeight: '15rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    sanitize: false,
    // toolbarPosition: 'top',
    outline: true,
    defaultFontName: 'ActualBold',
    defaultFontSize: '5',
    // showToolbar: false,
    defaultParagraphSeparator: 'p',
    fonts: [
      {class: 'ActualBold', name: 'ActualBold'},
      {class: 'ActualMedium', name: 'ActualMedium'},
      {class: 'Noto Sans KR', name:'Noto Sans KR'}
    ],
    customClasses: [
      {
        name: 'title',
        class: 'bh1v',
      },
      {
        name: 'subtitle',
        class: 'h1v',
      },
      {
        name: 'bigcontents',
        class: 'h1b',
      },
      {
        name: 'contents',
        class: 'ppvp',
      },

    ],
    toolbarHiddenButtons: [
      ['bold', 'italic'],
      ['fontSize']
    ]
  };
  constructor(public authService: AuthService, private formBuilder: FormBuilder, private ProductService : ProjectService) {

  }
  logoutUser() {
    this.authService.logout()
      .then(res => {
        console.log(res);
        console.log('로그아웃 합니다.');
      }, err => {
        window.alert(err.message);
      });
  }

  euserLogin(email, password) {
   return this.authService.afAuth.auth.setPersistence('session').then(()=>{
      this.authService.elogin(email, password)
      .then(userCredential => {
        this.authService.cuser = userCredential.user;
        console.log('안전하게 로그인 되었습니다.');
      }, err => {
        window.alert(err.message);
      });
    })
  }

  googleLogin() {
    this.authService.GoogleAuth()
      .then(res => {
        console.log(res);
      }, err => {
        console.log(err);
      });
  }

  onChange(event) {
    console.log('changed');
  }
  ngOnInit() {
    this.form = this.formBuilder.group({
      signature: ['', Validators.required]
    });
    console.log(this.htmlContent);

    this.ProductService.getProjects().pipe(first()).subscribe(Projects => {
      if(!Projects){
        console.log("no project");
        return;
      }else{
        this.Projects = Projects;
      }
    })
  }

}
