import { Component, OnInit } from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { AuthService } from "../../services/auth.service";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-newpost',
  templateUrl: './newpost.component.html',
  styleUrls: ['./newpost.component.scss']
})
export class NewpostComponent implements OnInit {
  htmlContent = '';
  htmlContent1 = '';

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    minHeight: '5rem',
    maxHeight: '15rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    sanitize: false,
    // toolbarPosition: 'top',
    outline: true,
    defaultFontName: 'ActualBold',
    defaultFontSize: '5',
    // showToolbar: false,
    defaultParagraphSeparator: 'p',
    fonts: [
      {class: 'ActualBold', name: 'ActualBold'},
      {class: 'ActualMedium', name: 'ActualMedium'},
      {class: 'Noto Sans KR', name:'Noto Sans KR'}
    ],
    customClasses: [
      {
        name: 'title',
        class: 'bh1v',
      },
      {
        name: 'subtitle',
        class: 'h1v',
      },
      {
        name: 'bigcontents',
        class: 'h1b',
      },
      {
        name: 'contents',
        class: 'ppvp',
      },

    ],
    toolbarHiddenButtons: [
      ['bold', 'italic'],
      ['fontSize']
    ]
  };
  constructor(public authService: AuthService, private formBuilder: FormBuilder){}

  onChange(event) {
    console.log('changed');
  }

  ngOnInit(): void {
  }

}
