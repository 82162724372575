import { Injectable } from '@angular/core';
import { Project } from '../model/project';
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators';
import { AngularFirestoreCollection, AngularFirestoreDocument, AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {
  ProjectCollection: AngularFirestoreCollection<Project>;
  Projects: Observable<Project[]>;
  ProjectDoc: AngularFirestoreDocument;
  SelecProject: Project;

  constructor(private afs: AngularFirestore) {
    this.ProjectCollection = this.afs.collection('Portf', ref => ref.orderBy('project_number', 'desc'));
    this.Projects = this.ProjectCollection.snapshotChanges()
      .pipe(map(docs => {
        return docs.map(doc => {
          const data = doc.payload.doc.data() as Project;
          data.id = doc.payload.doc.id;
          return data;
        })
      }))
  }

  getProjects(){
    return this.Projects;
  }
}
